import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TraceDiagnosisService } from 'src/app/shared/services/diagnosis.service/diagnosis.service';
import { FeedbackService } from 'src/app/shared/services/feedback.service';
import { environment } from '../../environment';
import { Diagnosis, DiagnosisForm, SensorEvent } from '../../shared/models/diagnosis.model';
import { NotificationService } from '../notification/notification.service';

@Component({
  selector: 'app-diagnosis-table',
  templateUrl: './diagnosis-table.component.html',
  styleUrls: ['./diagnosis-table.component.scss']
})
export class DiagnosisTableComponent {
  @Input() diagnoses: Diagnosis[];
  @Input() diagnosisForm: DiagnosisForm;
  dateTimeFormat: string;
  diagnosisForFeedback: Diagnosis = null;
  feedbackType: 'POSITIVE' | 'NEGATIVE' = null;

  constructor(
    private traceDiagnosisService: TraceDiagnosisService,
    private router: Router,
    private feedbackService: FeedbackService,
    private notificationService: NotificationService
  ) {
    this.dateTimeFormat = environment.dateTimeFormat;
  }

  navigateToTraceDiagnosis(sensorEntry: SensorEvent) {
    const { traceId } = sensorEntry;

    const {
      brand,
      from,
      to,
      modId,
      lang,
      role,
      onlyErrors: onlyFailures,
      pageSize
    } = this.diagnosisForm;

    this.traceDiagnosisService.setFormParams({
      brand,
      from,
      to,
      modId,
      lang,
      traceId,
      role,
      onlyErrors: onlyFailures,
      pageSize
    });

    this.router.navigateByUrl('/traceid-diagnosis');
  }

  onFeedbackButtonClick(type: 'POSITIVE' | 'NEGATIVE', diagnosis: Diagnosis) {
    this.diagnosisForFeedback = diagnosis;
    this.feedbackType = type;
  }

  onFeedbackModalSend(feedback: string) {
    const { brand } = this.diagnosisForm;

    this.feedbackService
      .sendFeedback(this.feedbackType, brand, this.diagnosisForFeedback, feedback)
      .subscribe(() => {
        this.notificationService.success('DIAGNOSIS.FEEDBACK.THANK_YOU', null);
        this.onCloseFeedbackModal();
      });
  }

  onCloseFeedbackModal() {
    this.diagnosisForFeedback = null;
    this.feedbackType = null;
  }
}
