<div class="row row--container mar-top-large">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <div class="row">
          <div class="col sm-6 pad-top-medium pad-bottom-medium" name="whitelistCount">
            <span class="h4">{{ 'UNAUTHORIZED_ACCESS.TITLE' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row row--container">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <div class="row">
          <div class="col sm-10 cursor-pointer bottom-margin">
            <h2 class="subtitle">
              {{ 'UNAUTHORIZED_ACCESS.HEADER' | translate }}
            </h2>
          </div>
        </div>
        <hr />
        <h1 class="subtitle font-size-small unauthorized-content">
          {{ 'UNAUTHORIZED_ACCESS.CONTENT' | translate }}
        </h1>
        <br />
        <h1 class="subtitle font-size-small">
          {{ 'UNAUTHORIZED_ACCESS.CONTENT2' | translate: { x: email } }}
        </h1>
      </div>
    </div>
  </div>
</div>
