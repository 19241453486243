<div class="row row--container">
  <div class="col sm-12">
    <div class="card">
      <div class="card__content diagnosis-table">
        <div
          *ngFor="let diagnosis of diagnoses; let i = index"
          class="diagnosis-table-content"
          [ngClass]="{
            error: diagnosis.failureType === 'error',
            success: diagnosis.failureType === 'success',
            topborder: i !== 0
          }"
        >
          <div class="row diagnosis-header">
            <b class="col sm-9 pad-vertical-small pad-horizontal-small">
              {{ 'DIAGNOSIS.TABLE.SOURCE' | translate }}
              {{ diagnosis.modName + ' [' + diagnosis.modId + ']' }}
            </b>
            <div class="col sm-3 pad-vertical-small pad-horizontal-small" style="text-align: right">
              <p *ngIf="diagnosis.events != null">
                {{ diagnosis.events[0]?.eventTime | date: dateTimeFormat }}
              </p>
              <p *ngIf="diagnosis.events == null">
                {{ diagnosis.eventTime | date: dateTimeFormat }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col sm-4 pad-vertical-medium pad-horizontal-small">
              <mat-form-field>
                <textarea disabled matInput cdkTextareaAutosize>{{
                  diagnosis.failureDescription
                }}</textarea>
              </mat-form-field>
            </div>
            <div class="col sm-7 pad-vertical-medium pad-horizontal-small">
              <mat-form-field>
                <textarea disabled matInput cdkTextareaAutosize>{{ diagnosis.solution }}</textarea>
              </mat-form-field>
            </div>
            <div
              class="col sm-1 pad-vertical-medium pad-horizontal-small flex flex--direction-column flex--align-items-center"
            >
              <button
                class="btn btn--fab btn--tertiary mar-small"
                type="submit"
                title="{{ 'DIAGNOSIS.TABLE.LIKE' | translate }}"
                (click)="onFeedbackButtonClick('POSITIVE', diagnosis)"
              >
                <i class="icon i-like like"></i>
              </button>
              <button
                class="btn btn--fab btn--tertiary mar-small"
                type="submit"
                title="{{ 'DIAGNOSIS.TABLE.DISLIKE' | translate }}"
                (click)="onFeedbackButtonClick('NEGATIVE', diagnosis)"
              >
                <i class="icon i-like dislike"></i>
              </button>
            </div>
          </div>
          <div *ngIf="diagnosis.events != null" class="row">
            <table id="entriesTable" name="entriesTable" class="table">
              <thead>
                <tr>
                  <th class="th_statuscode">
                    <small> {{ 'DIAGNOSIS.TABLE.STATUSCODE' | translate }}</small>
                  </th>
                  <th class="th_usecase">
                    <small> {{ 'DIAGNOSIS.TABLE.USE_CASE' | translate }}</small>
                  </th>
                  <th class="th_action">
                    <small> {{ 'DIAGNOSIS.TABLE.ACTION' | translate }}</small>
                  </th>
                  <th class="th_component">
                    <small> {{ 'DIAGNOSIS.TABLE.COMPONENT' | translate }}</small>
                  </th>
                  <th class="th_sensorid">
                    <small> {{ 'DIAGNOSIS.TABLE.SENSOR_ID' | translate }}</small>
                  </th>
                  <th class="th_traceid">
                    <small> {{ 'DIAGNOSIS.TABLE.TRACE_ID' | translate }}</small>
                  </th>
                  <th class="th_preecedingtraceid">
                    <small>{{ 'DIAGNOSIS.TABLE.PREECEDING_TRACE_ID' | translate }}</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let event of diagnosis.events">
                  <td title="{{ event.statusCode }}">
                    <small>{{ event.statusCode }}</small>
                  </td>
                  <td title="{{ event.useCase }}">
                    <small>{{ event.useCase }}</small>
                  </td>
                  <td title="{{ event.action }}">
                    <small>{{ event.action }}</small>
                  </td>
                  <td title="{{ event.component }}">
                    <small>{{ event.component }}</small>
                  </td>
                  <td title="{{ event.sensorId }}">
                    <small>{{ event.sensorId }}</small>
                  </td>
                  <td class="traceid" title="{{ event.traceId }}">
                    <small>
                      <a
                        class="pad-small"
                        href="javascript:void(0)"
                        (click)="navigateToTraceDiagnosis(event)"
                      >
                        {{ event.traceId }}
                      </a>
                    </small>
                  </td>
                  <td title="{{ event.precedingTraceId }}">
                    <small>{{ event.precedingTraceId }}</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <app-feedback-modal
          (closeModal)="onCloseFeedbackModal()"
          (send)="onFeedbackModalSend($event)"
          *ngIf="!!diagnosisForFeedback"
          [type]="this.feedbackType"
        >
        </app-feedback-modal>

      </div>
    </div>
  </div>
</div>
