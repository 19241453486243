<div class="taskbar taskbar--top">
  <img class="taskbar__logo" alt="logo" src="/assets/images/vw-logo-neu.svg" />
  <div class="taskbar__header">
    <h1>
      <a href="#">{{ 'HEADER.TITLE' | translate }}</a>
    </h1>
  </div>
  <div class="iconbar">
    <div class="horizontal-margins-small">
      <button *ngIf="regions.includes('en')" class="btn btn--fab" (click)="changeLanguage('en')">
        <img alt="EN" class="taskbar__picture" src="/assets/images/flag-gb.png" />
      </button>
      <button *ngIf="regions.includes('de')" class="btn btn--fab" (click)="changeLanguage('de')">
        <img alt="DE" class="taskbar__picture" src="/assets/images/flag-germany.png" />
      </button>
      <button *ngIf="regions.includes('zh')" class="btn btn--fab" (click)="changeLanguage('zh')">
        <img alt="DE" class="taskbar__picture" src="/assets/images/flag-cn.png" />
      </button>
    </div>
  </div>

  <details class="dropdown dropdown--left">
    <summary class="dropdown__indicator">
      <b *ngIf="user" id="username" class="pad-large"> {{ user.name }} </b>
      <div class="taskbar__picture avatar">
        <app-form-aware-avatar></app-form-aware-avatar>
      </div>
      <i class="icon i-dropdown-closed"></i>
    </summary>
    <div class="dropdown__content">
      <ul class="pad-horizontal-medium list list--no-link-icon">
        <li>
          <a *ngIf="isLoggedIn()" class="pad-small" routerLink="/profile">{{
            'HEADER.TASKBAR.PROFILE' | translate
          }}</a>
        </li>
        <li>
          <a *ngIf="isLoggedIn()" class="pad-small" href="javascript:void(0)" (click)="logout()">{{
            'HEADER.TASKBAR.LOGOUT' | translate
          }}</a>
        </li>
        <li *ngIf="!isLoggedIn()">
          <a href="/login" class="pad-small">{{ 'HEADER.TASKBAR.LOGIN' | translate }}</a>
        </li>
      </ul>
    </div>
  </details>
</div>

<nav class="navbar">
  <ul>
    <li [ngClass]="{ 'is-active': this.router.isActive('vin-diagnosis', false) }">
      <a routerLink="/vin-diagnosis">{{ 'HEADER.NAVIGATION.VIN_DIAGNOSIS' | translate }}</a>
    </li>
    <li
      [ngClass]="{
        'is-active': this.router.isActive('traceid-diagnosis', false)
      }"
      *ngIf="rolesService.checkIfUserHasAnyOfPermissions(ADMIN_COMPETENCE)"
    >
      <a routerLink="/traceid-diagnosis">{{ 'HEADER.NAVIGATION.TRACEID_DIAGNOSIS' | translate }}</a>
    </li>
  </ul>
</nav>
