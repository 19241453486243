<ul class="accordion" name="newslettterAccordion" *ngIf="newsletters.length > 0">
  <li class="is-open" [ngClass]="{ 'is-open': stayOpen == true }">
    <div class="accordion__title" (click)="toggleOpen()" name="newslettterAccordion-Title"></div>

    <div class="accordion__content bg-primary100">
      <div class="card bg-primary100">
        <div class="card__content text-white">
          <h1 class="h5 center text-white" name="newsletterTitle">
            {{ this.selectedNewsletter.title || 'NEWSLETTER-DASHBOARD.EMPTY-CONTENT' | translate }}
          </h1>
          <div class="col sm-8 sm-offset-2 text-white" name="newsletterContent">
            <p>
              {{
                this.selectedNewsletter.content || 'NEWSLETTER-DASHBOARD.EMPTY-CONTENT' | translate
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
<div class="center bg-primary100" *ngIf="newsletters.length > 0 && stayOpen">
  <ol class="dots">
    <li
      class="dots__dot"
      id="newsletter-{{ i }}"
      *ngFor="let newsletter of newsletters; let i = index"
      (click)="onClicKDot(i)"
      [ngClass]="{ 'dots__dot--active': newsletterIndex === i }"
    ></li>
  </ol>
</div>
