import { EventEmitter, Injectable } from '@angular/core';
import { Role } from '../constants';
import { MappedRoles, RoleChange } from '../models/mapped-roles.model';
import { UserService } from './user-service.service';

@Injectable()
export class RolesService {
  private ROLE_HIERARCHY = [
    Role.ADMIN,
    Role.COMPETENCE_CENTER,
    Role.CUSTOMER_CARE,
    Role.WORKSHOP,
    Role.CUSTOMER
  ] as const;

  public onRoleChange: EventEmitter<RoleChange> = new EventEmitter();

  constructor(private userService: UserService) {}

  private getMappedRoles(): MappedRoles {
    return this.userService.getUser().getValue()?.mappedRoles || {};
  }

  getDominantRole(): Role {
    const mostDominantRoleIndex = Object.values(this.getMappedRoles())
      .map(
        (rolesForBrand) =>
          rolesForBrand
            .map((role) => this.ROLE_HIERARCHY.findIndex((val) => val === role))
            .sort()[0]
      )
      .sort()[0];

    return this.ROLE_HIERARCHY[mostDominantRoleIndex];
  }

  checkIfUserHasAnyOfPermissions(permissions: Role[]): boolean {
    for (const roles of Object.values(this.getMappedRoles())) {
      for (const permission of permissions) {
        if (roles.includes(permission)) {
          return true;
        }
      }
    }
    return false;
  }

  getBrandsOfUser(): string[] {
    return Object.keys(this.getMappedRoles());
  }

  getBrandsOfUserWhereCustomerOrCompetence(): string[] {
    return Object.entries(this.getMappedRoles())
      .filter(
        ([brand, roles]) =>
          roles.includes(Role.COMPETENCE_CENTER) || roles.includes(Role.CUSTOMER_CARE)
      )
      .map((roleMapping) => roleMapping[0]);
  }

  getMappedRolesForBrand(brand: string): Role[] {
    return this.getMappedRoles()[brand];
  }
}
