<div
  class="vin-diagnosis-component"
  *ngIf="rolesService.checkIfUserHasAnyOfPermissions(ALL_ROLES); else elseBlock"
>
  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card">
        <div class="card__content">
          <h1 class="h5 center">{{ 'DIAGNOSIS.TITLE' | translate }}</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card">
        <div class="card__content">
          <form (ngSubmit)="onSubmit()" [formGroup]="diagnosisForm">
            <div class="row horizontal-margins-small pad-horizontal-small">
              <div
                *ngFor="
                  let brand of rolesService.getBrandsOfUserWhereCustomerOrCompetence();
                  let i = index
                "
              >
                <div
                  class="btn btn--fab"
                  [ngClass]="diagnosisForm.get(BRAND_KEY).value == brand ? 'btn--primary' : ''"
                  (click)="changeBrandValue(brand)"
                  id="{{ brand }}-button"
                >
                  <img
                    alt="{{ brand }}"
                    class="taskbar__picture"
                    src="/assets/images/brand-logos/{{ brand }}.png"
                  />
                </div>
              </div>
              <div class="roles-wrapper horizontal-margins-small">
                <div
                  *ngFor="let role of brandRoles; let i = index"
                  class="btn btn--fab"
                  [ngClass]="diagnosisForm.get(ROLE_KEY).value == role ? 'btn--primary' : ''"
                  id="{{ role }}-button"
                >
                  <img
                    class="taskbar__picture"
                    src="assets/images/role-avatars/ICON_{{ getRoleToDisplay(role) }}.png"
                    alt="{{ getRoleToDisplay(role) }}"
                    (click)="changeFormRoleValue(role)"
                    title="{{ role }}"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col sm-3">
                <div class="wrapper wrapper--input">
                  <label for="VINInput">{{ 'DIAGNOSIS.FORM.VIN' | translate }}</label>
                  <input
                    id="VINInput"
                    name="VINInput"
                    type="text"
                    mask="AAAAAAAAAAAAAAAAA"
                    formControlName="{{ VIN_KEY }}"
                  />
                </div>
              </div>
              <div class="col sm-3">
                <div class="wrapper wrapper--input">
                  <label for="UserInput">{{ 'DIAGNOSIS.FORM.USER' | translate }}</label>
                  <input
                    id="UserInput"
                    name="UserInput"
                    type="text"
                    formControlName="{{ USER_KEY }}"
                  />
                </div>
              </div>
              <div class="col sm-3">
                <label for="ModIDInput"
                  >{{ 'DIAGNOSIS.FORM.NAME' | translate }}
                  <div class="wrapper wrapper--select">
                    <select
                      id="ModIDInput"
                      name="ModIDInput"
                      formControlName="{{ MODID_KEY }}"
                      required
                      (change)="updateSupportedLanguages()"
                    >
                      <optgroup
                        *ngIf="customerServicesToDisplay.length !== 0"
                        label="{{ 'DIAGNOSIS.FORM.CUSTOMER_SERVICES' | translate }}"
                      >
                        <option
                          *ngFor="let customerService of customerServicesToDisplay; let i = index"
                          [ngValue]="customerService.modId"
                        >
                          {{ customerService.name }}
                        </option>
                      </optgroup>
                      <optgroup
                        *ngIf="servicesToDisplay.length !== 0"
                        label="{{ 'DIAGNOSIS.FORM.CORE_SERVICES' | translate }}"
                      >
                        <option
                          *ngFor="let service of servicesToDisplay; let i = index"
                          [ngValue]="service.modId"
                        >
                          {{ service.name }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                </label>
              </div>
              <div class="col sm-3">
                <label for="LanguageSelect"
                  >{{ 'DIAGNOSIS.FORM.LANGUAGE' | translate }}
                  <div class="wrapper wrapper--select">
                    <select
                      id="LanguageSelect"
                      name="LanguageSelect"
                      formControlName="{{ LANG_KEY }}"
                      required
                    >
                      <option
                        *ngFor="let language of languages; let i = index"
                        [ngValue]="language"
                        [selected]="language == diagnosisForm.get(LANG_KEY).value"
                      >
                        {{ language }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="wrapper wrapper--input col sm-3">
                <label for="StartTimeInput">{{ 'DIAGNOSIS.FORM.STARTTIME' | translate }}</label>
                <input
                  id="StartTimeInput"
                  name="StartTimeInput"
                  type="text"
                  [owlDateTimeTrigger]="dtFrom"
                  [owlDateTime]="dtFrom"
                  [min]="earliestDate()"
                  [max]="latestDate()"
                  formControlName="{{ FROM_KEY }}"
                />
                <owl-date-time #dtFrom></owl-date-time>
              </div>
              <div class="wrapper wrapper--input col sm-3">
                <label for="EndTimeInput">{{ 'DIAGNOSIS.FORM.ENDTIME' | translate }}</label>
                <input
                  id="EndTimeInput"
                  name="EndTimeInput"
                  type="text"
                  [owlDateTimeTrigger]="dtTo"
                  [owlDateTime]="dtTo"
                  [min]="earliestDate()"
                  [max]="latestDate()"
                  formControlName="{{ TO_KEY }}"
                />
                <owl-date-time #dtTo></owl-date-time>
              </div>

              <div class="col sm-3">
                <label for="PageSizeInput"
                  >{{ 'DIAGNOSIS.FORM.PAGE_SIZE' | translate }}
                  <div class="wrapper wrapper--select">
                    <select
                      id="PageSizeInput"
                      name="PageSizeInput"
                      formControlName="{{ PAGE_SIZE_KEY }}"
                      required
                    >
                      <option
                        *ngFor="let pageSizeOption of pageSizeOptions; let i = index"
                        [ngValue]="pageSizeOption"
                        [selected]="pageSizeOption == diagnosisForm.get(PAGE_SIZE_KEY).value"
                      >
                        {{ pageSizeOption }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>

              <div class="wrapper wrapper--input col sm-3">
                <br />
                <input
                  id="ResultType"
                  name="ResultType"
                  type="checkbox"
                  formControlName="{{ FILTER_TOGGLE_KEY }}"
                />
                <label class="is-switch" for="ResultType">
                  {{ 'DIAGNOSIS.FORM.RESULT_FILTER' | translate }}
                </label>
              </div>

              <div class="col">
                <div class="wrapper center">
                  <br />
                  <button
                    type="submit"
                    id="diagnosisFormSubmit"
                    class="btn btn--primary"
                    [attr.disabled]="
                      servicesToDisplay.length === 0 && customerServicesToDisplay.length === 0
                        ? true
                        : null
                    "
                  >
                    {{ 'DIAGNOSIS.FORM.SUBMIT' | translate }}
                  </button>
                </div>
              </div>
            </div>

            <app-form-error
              [visible]="diagnosisForm.controls.to.hasError('forbidden') && submitted"
            >
              {{ 'DIAGNOSIS.FORM.DATE_TO_NOT_VALID' | translate }}
            </app-form-error>

            <app-form-error
              [visible]="diagnosisForm.controls.from.hasError('forbidden') && submitted"
            >
              {{ 'DIAGNOSIS.FORM.DATE_FROM_NOT_VALID' | translate }}
            </app-form-error>

            <app-form-error [visible]="diagnosisForm.controls.vin.hasError('size') && submitted">
              {{ 'DIAGNOSIS.FORM.VIN_ERROR' | translate }}
            </app-form-error>

            <app-form-error [visible]="diagnosisForm.controls.user.hasError('size') && submitted">
              {{ 'DIAGNOSIS.FORM.USER_ERROR_SIZE' | translate }}
            </app-form-error>

            <app-form-error [visible]="diagnosisForm.controls.user.hasError('regexp') && submitted">
              {{
                (features.get('emailSearch')
                  ? 'DIAGNOSIS.FORM.USER_REGEXP'
                  : 'DIAGNOSIS.FORM.USER_REGEXP_ZH'
                ) | translate
              }}
            </app-form-error>

            <app-form-error [visible]="diagnosisForm.hasError('vinOrUser') && submitted">
              {{ 'DIAGNOSIS.FORM.USER_VIN_ERROR' | translate }}
            </app-form-error>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container" *ngIf="warningMessage">
    <div class="col sm-12">
      <div class="card not-found" *ngFor="let entry of warningMessage | keyvalue">
        <div>
          <b *ngIf="entry.key !== ' '">{{ entry.key }}: </b>{{ entry.value | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="row row--container" *ngIf="backendCalled">
    <div class="col sm-12">
      <div class="card">
        <div class="card__content col sm-4 sm-offset-5">
          <div class="preloader preloader--golf"></div>
          <h6>{{ 'DIAGNOSIS.FORM.DIAGNOSIS_IN_PROGRESS' | translate }}</h6>
        </div>
      </div>
    </div>
  </div>

  <app-diagnosis-table
    *ngIf="diagnoses"
    [diagnoses]="diagnoses"
    [diagnosisForm]="diagnosisForm.value"
  ></app-diagnosis-table>
</div>

<ng-template #elseBlock>
  <app-unathorized-access></app-unathorized-access>
</ng-template>
