<footer class="footer">
  <div class="footer__links">
    <ul id="footer_list">
      <li class="footer_list_item">
        <a
          href="https://www.vw.com.cn/"
          target="_blank"
          >{{ 'FOOTER.IMPRINT' | translate }}</a
        >
      </li>
      <li class="footer_list_item">
        <a routerLink="/dsgvo">{{ 'FOOTER.LEGAL' | translate }}</a>
      </li>
      <li class="footer_list_item">
        <a routerLink="/help">{{ 'FOOTER.HELP' | translate }}</a>
      </li>
    </ul>
  </div>
</footer>
