/** At least vin or user id has to be filled */
import { ValidatorFn } from 'ngx-typesafe-forms';

export const userOrVinValidator = <T extends { user: string; vin: string }>(): ValidatorFn<T> => (
  control
) => {
  const { vin, user } = control.value;

  return vin?.length !== 0 || user?.length !== 0 ? null : { vinOrUser: true };
};
