import { HttpErrorResponse } from '@angular/common/http';
import { OnInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from 'ngx-typesafe-forms';
import { filter, take } from 'rxjs/operators';
import { DiagnosisService } from 'src/app/shared/services/diagnosis.service/diagnosis.service';
import { Constants, Role } from '../../../shared/constants';
import { CoreService, ServiceType } from '../../../shared/interfaces/core-service.interface';
import {
  Diagnosis,
  DiagnosisForm,
  DiagnosisResponse
} from '../../../shared/models/diagnosis.model';
import { RolesService } from '../../../shared/services/roles-service.service';
import { UserService } from '../../../shared/services/user-service.service';
import { ServicesService } from '../../../shared/services/services.service';
import { arrayContainsValidators } from '../../../shared/validators/array-contains-validator.directive';
import { dateTimeValidator } from '../../../shared/validators/time-validator.directive';
import { NotificationService } from '../../notification/notification.service';
import { SensorEvent } from '../../../shared/models/diagnosis.model';

@Component({ template: '' })
export abstract class BaseDiagnosisComponent<T extends DiagnosisForm> implements OnInit {
  public readonly TO_KEY = 'to';
  public readonly FROM_KEY = 'from';
  public readonly LANG_KEY = 'lang';
  public readonly MODID_KEY = 'modId';
  public readonly BRAND_KEY = 'brand';
  public readonly ROLE_KEY = 'role';
  public readonly FILTER_TOGGLE_KEY = 'onlyErrors';
  public readonly PAGE_SIZE_KEY = 'pageSize';
  public backendCalled = false;

  readonly ALL_ROLES: Role[] = Constants.ALL_ROLES;
  public brandRoles: Role[];
  languages: string[] = [];
  services: CoreService[] = [];
  servicesToDisplay: CoreService[] = [];
  customerServicesToDisplay: CoreService[] = [];
  diagnoses: Diagnosis[];
  public submitted = false;
  public warningMessage: Map<string, string>;
  public diagnosisForm: FormGroup<T>;
  pageSizeOptions: number[] = [30, 50, 100];

  constructor(
    private translateService: TranslateService,
    private servicesService: ServicesService,
    protected diagnosisService: DiagnosisService<T>,
    private notificationService: NotificationService,
    private userService: UserService,
    public rolesService: RolesService
  ) {}

  protected abstract extendForm(form: FormGroup<T>);

  onSubmit() {
    this.warningMessage = null;
    this.diagnoses = null;
    this.submitted = true;

    const request = this.diagnosisForm.getRawValue();
    console.log('Request value');
    console.log(request);
    if (this.diagnosisForm.valid) {
      this.backendCalled = true;
      this.diagnosisService.getDiagnosis(request).subscribe(
        (data: DiagnosisResponse) => {
          this.diagnoses = data.diagnoses;
          this.backendCalled = false;

          if (data.diagnoses.length < data.total) {
            this.notificationService.warning(
              'DIAGNOSIS.WARNING.EXCEEDED_RESULTS.TITLE',
              'DIAGNOSIS.WARNING.EXCEEDED_RESULTS.' + (request.onlyErrors ? 'ONLY_ERRORS' : 'ALL')
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.backendCalled = false;
          if (error.status === 404) {
            this.warningMessage = error.error.errorMessages;
            if (this.warningMessage.size === 0) {
              this.warningMessage = new Map([[' ', error.error.message]]);
            }
          } else {
            console.error(error);

            const requestId = error.headers.has('e2ed-requestId')
              ? ' requestId ' + error.headers.get('e2ed-requestId')
              : '';

            this.notificationService.error('ERROR' + requestId, error.error.message);
          }
        }
      );
    }
  }

  ngOnInit() {
    const {
      from,
      to,
      modId,
      lang,
      brand,
      role,
      onlyErrors: onlyFailures,
      pageSize
    } = this.diagnosisService.getFormValue();
    // @ts-ignore 'modId', 'lang', 'from' and 'to' have to be in the form
    this.diagnosisForm = new FormGroup<T>({
      [this.FROM_KEY]: new FormControl(from, [dateTimeValidator()]),
      [this.TO_KEY]: new FormControl(to, [dateTimeValidator()]),
      [this.MODID_KEY]: new FormControl(modId),
      [this.LANG_KEY]: new FormControl(lang),
      [this.BRAND_KEY]: new FormControl(brand, [
        arrayContainsValidators(this.rolesService.getBrandsOfUserWhereCustomerOrCompetence())
      ]),
      [this.ROLE_KEY]: new FormControl(role, [arrayContainsValidators(this.ALL_ROLES)]),
      [this.FILTER_TOGGLE_KEY]: new FormControl(onlyFailures),
      [this.PAGE_SIZE_KEY]: new FormControl(pageSize)
    });

    this.changeRolesToDisplay(brand);

    this.extendForm(this.diagnosisForm);

    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe(() => this.getServices());

    this.diagnoses = this.diagnosisService.getCachedDiagnosis()?.diagnoses;
  }

  private getServices() {
    this.servicesService.getCoreServices().subscribe((result) => {
      this.services = result;
      this.filterVisibleServices(this.diagnosisService.getFormValue().brand, false);
    });
  }

  earliestDate() {
    const date = new Date();
    date.setDate(date.getDate() - 14);
    return date;
  }

  latestDate() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }

  changeBrandValue(brand: string) {
    this.diagnosisForm.controls.brand.setValue(brand);
    this.changeRolesToDisplay(brand);
    this.filterVisibleServices(brand, true);
  }

  changeFormRoleValue(role: Role) {
    this.rolesService.onRoleChange.emit({
      brand: this.diagnosisForm.controls.brand.value,
      role
    });
    this.diagnosisForm.controls.role.setValue(role);
  }

  filterVisibleServices(brand: string, resetControl: boolean) {
    this.servicesToDisplay = this.services.filter(
      (value) => value.brand === brand && value.type === ServiceType.CORE
    );
    this.customerServicesToDisplay = this.services.filter(
      (value) => value.brand === brand && value.type === ServiceType.CUSTOMER
    );

    const modId =
      (!resetControl && this.diagnosisService.getFormValue().modId) ||
      this.customerServicesToDisplay[0]?.modId ||
      this.servicesToDisplay[0]?.modId;

    this.diagnosisForm.setControl(
      // @ts-ignore modId will always be inside of the request
      this.MODID_KEY,
      new FormControl(modId)
    );

    if (this.servicesToDisplay.length === 0 && this.customerServicesToDisplay.length === 0) {
      this.warningMessage = new Map([[' ', 'DIAGNOSIS.WARNING.NO_SERVICES']]);
      this.diagnosisForm.disable();
    } else {
      this.warningMessage = null;
      this.diagnosisForm.enable();
    }
    this.updateSupportedLanguages();
  }

  updateSupportedLanguages() {
    const modId: string = this.diagnosisForm.controls.modId.value;

    const coreService: CoreService = this.servicesToDisplay.find(
      (service) => service.modId === modId
    );

    const customerService: CoreService = this.customerServicesToDisplay.find(
      (service) => service.modId === modId
    );

    if (coreService !== undefined) {
      this.languages = coreService.supportedLanguages;
    } else if (customerService !== undefined) {
      this.languages = customerService.supportedLanguages;
    }

    if (
      this.languages.length === 0 &&
      (coreService !== undefined || customerService !== undefined)
    ) {
      console.error('No supported languages found');
      this.notificationService.error(
        'ERROR',
        'No supported languages found for service with modId: ' + modId
      );
    }
  }

  changeRolesToDisplay(brand: string) {
    this.brandRoles = this.rolesService.getMappedRolesForBrand(brand);
    this.changeFormRoleValue(this.brandRoles[0]);
  }

  getRoleToDisplay(role: Role): Role {
    if (this.checkIfProductionSelected() && role === Role.COMPETENCE_CENTER) {
      return Role.INSPECTOR;
    }
    return role;
  }

  private checkIfProductionSelected(): boolean {
    return this.diagnosisForm.controls.brand.value === Constants.BRAND_PRODUCTION;
  }
}
