import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constants } from '../../constants';
import {
  DiagnosisForm,
  DiagnosisRequest,
  DiagnosisResponse,
  TraceRequestForm,
  TraceIdRequest,
  VinRequest,
  VinRequestForm
} from '../../models/diagnosis.model';
import { RolesService } from '../roles-service.service';
import { DiagnosisDTO } from './diagnosis.service.dtos';

export abstract class DiagnosisService<
  T extends DiagnosisForm,
  R extends DiagnosisRequest = DiagnosisRequest
> {
  protected constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private rolesService: RolesService,
    initialFormValues: Omit<T, keyof DiagnosisForm>
  ) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // @ts-ignore incoming initialFormValues must contain every property that's present in T, but missing in DiagnosisForm
    this.formCache = {
      ...initialFormValues,
      brand: this.rolesService.getBrandsOfUser()[0],
      from: yesterday,
      lang: this.translateService.getDefaultLang(),
      modId: null,
      to: new Date(),
      role: rolesService.getMappedRolesForBrand(this.rolesService.getBrandsOfUser()[0])[0],
      onlyErrors: false,
      pageSize: 30
    };
  }

  private resultsCache: DiagnosisResponse = null;
  protected formCache: T;

  protected abstract mapRequest(form: T): R;

  public getDiagnosis(form: T): Observable<DiagnosisResponse> {
    this.formCache = form;

    const headers = this.getHttpOptions(form.lang);
    const request = this.mapRequest(form);
    const params = this.skipEmptyParams({
      ...request,
      from: request.from.toISOString(),
      to: request.to.toISOString(),
      pageSize: request.pageSize.toString()
    });

    return this.http
      .post<DiagnosisDTO[]>(Constants.DIAGNOSIS_URL, params, {
        headers,
        observe: 'response'
      })
      .pipe(
        catchError((err) => {
          this.resultsCache = null;
          console.error(err);
          return throwError(err);
        })
      )
      .pipe(map((val) => this.mapResponse(val)))
      .pipe(map((x) => (this.resultsCache = x)));
  }

  public getCachedDiagnosis(): DiagnosisResponse {
    return { ...this.resultsCache };
  }

  // gets initial or cached form value
  public getFormValue(): T {
    return { ...this.formCache };
  }

  private mapResponse(response: HttpResponse<DiagnosisDTO[]>): DiagnosisResponse {
    return {
      diagnoses: response.body.map((data) => ({
        ...data,
        eventTime: new Date(data.eventTime),
        failureType: data.failureType,
        events:
          data.events &&
          data.events.map((entry) => ({
            ...entry,
            eventTime: new Date(entry.eventTime)
          }))
      })),
      total: parseInt(response.headers.get('X-E2ED-TOTAL'), 10) || 0
    };
  }

  private getHttpOptions(lang: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept-Language': lang
    });
    return headers;
  }

  private skipEmptyParams = <X>(params: { [ss in keyof X]: X[ss] }) =>
    Object.entries(params).reduce(
      (acc, curr) => (curr[1] ? { ...acc, [curr[0]]: curr[1] } : acc),
      {} as X
    );
}

@Injectable({
  providedIn: 'root'
})
export class TraceDiagnosisService extends DiagnosisService<TraceRequestForm, TraceIdRequest> {
  constructor(http: HttpClient, translateService: TranslateService, rolesService: RolesService) {
    super(http, translateService, rolesService, { traceId: '' });
  }

  protected mapRequest(form: TraceRequestForm): TraceIdRequest {
    const traceIdRequest: TraceIdRequest = {
      ...form,
      resultFilter: form.onlyErrors ? 'ONLY_ERRORS' : 'ALL'
    };

    return traceIdRequest;
  }

  public setFormParams(form: TraceRequestForm) {
    this.formCache = form;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VinDiagnosisService extends DiagnosisService<VinRequestForm, VinRequest> {
  constructor(http: HttpClient, translateService: TranslateService, rolesService: RolesService) {
    // ETED-1325 Transfer GET to POST request.
    // these cookies are set by the backend
    super(http, translateService, rolesService, {
      user: VinDiagnosisService.getCookie('forwarded_userId') || '',
      vin: VinDiagnosisService.getCookie('forwarded_vin') || ''
    });

    VinDiagnosisService.removeCookie('forwarded_userId');
    VinDiagnosisService.removeCookie('forwarded_vin');
  }

  private static getCookie(name) {
    return (
      (name = (document.cookie + ';').match(new RegExp(name + '=.*;'))) && name[0].split(/=|;/)[1]
    );
  }

  private static removeCookie(name) {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }

  private checkIfAppropriateValue(regExp: RegExp, value: string): any {
    return regExp.test(value) ? value : null;
  }

  protected mapRequest(form: VinRequestForm): VinRequest {
    const vinRequest: VinRequest = {
      from: form.from,
      to: form.to,
      modId: form.modId,
      vin: form.vin,
      brand: form.brand,
      userId: this.checkIfAppropriateValue(Constants.userIdPattern, form.user),
      userEmail: this.checkIfAppropriateValue(Constants.emailPattern, form.user),
      role: form.role,
      resultFilter: form.onlyErrors ? 'ONLY_ERRORS' : 'ALL',
      pageSize: form.pageSize
    };
    return vinRequest;
  }
}
