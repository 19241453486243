import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule } from 'angular-oauth2-oidc';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { FormAwareAvatarComponent } from './components/avatar/form-aware-avatar.component';
import { DiagnosisTableComponent } from './components/diagnosis-table/diagnosis-table.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationListComponent } from './components/notification/notification.component';
import { UnathorizedAccessComponent } from './components/unauthorized-access/unathorized-access.component';
import { DefaultOAuthInterceptor } from './oauthinterceptor';
import { ContactpersonsComponent } from './pages/contactpersons/contactpersons.component';
import { DsgvoComponent } from './pages/dsgvo/dsgvo.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { TraceIdDiagnosisComponent } from './pages/traceid-diagnosis/traceid-diagnosis.component';
import { VinDiagnosisComponent } from './pages/vin-diagnosis/vin-diagnosis.component';
import { RolesService } from './shared/services/roles-service.service';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';

const appRoutes: Routes = [
  {
    path: 'dsgvo',
    component: DsgvoComponent
  },
  {
    path: 'help',
    component: ContactpersonsComponent
  },
  {
    path: 'profile',
    component: ProfilePageComponent
  },
  {
    path: 'traceid-diagnosis',
    component: TraceIdDiagnosisComponent
  },
  {
    path: 'vin-diagnosis',
    component: VinDiagnosisComponent
  },
  {
    path: '',
    redirectTo: 'vin-diagnosis',
    pathMatch: 'prefix'
  }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    VinDiagnosisComponent,
    DiagnosisTableComponent,
    UnathorizedAccessComponent,
    DsgvoComponent,
    ContactpersonsComponent,
    TraceIdDiagnosisComponent,
    FormErrorComponent,
    NotificationListComponent,
    ProfilePageComponent,
    AvatarComponent,
    FormAwareAvatarComponent,
    FeedbackModalComponent,
    NewsletterComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),
    FormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    MatInputModule
  ],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true
    },
    {
      provide: OWL_DATE_TIME_LOCALE,
      useValue: 'DE-de'
    },
    RolesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
