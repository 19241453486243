export enum Role {
  ADMIN = 'ADMIN',
  CUSTOMER_CARE = 'CUSTOMERCARE',
  COMPETENCE_CENTER = 'COMPETENCECENTER',
  INSPECTOR = 'INSPECTOR',
  WORKSHOP = 'WORKSHOP',
  CUSTOMER = 'CUSTOMER'
}

export class Constants {
  private static configServiceBaseURL = '/api';

  public static CORE_SERVICES_URL = `${Constants.configServiceBaseURL}/v1/services`;
  public static DIAGNOSIS_URL = `/api/v1/diagnosis`;
  public static FEEDBACK_URL = `/api/v1/feedback`;
  public static NEWSLETTERS_URL = `/api/v1/newsletters`;

  public static ALL_ROLES: Role[] = Object.keys(Role).map((role) => Role[role]);

  public static userIdPattern = new RegExp(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
  public static emailPattern = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
  public static BRAND_PRODUCTION = 'PRODUCTION';
}
