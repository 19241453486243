/** Time input should be between these two dates */
import { ValidatorFn } from 'ngx-typesafe-forms';

export function dateTimeValidator(): ValidatorFn<Date> {
  return (control) => {
    const earliestDate = new Date();
    earliestDate.setDate(earliestDate.getDate() - 14);

    const latestDate = new Date();
    latestDate.setDate(latestDate.getDate() + 1);

    return control.value < earliestDate || control.value > latestDate
      ? { forbidden: { value: control.value } }
      : null;
  };
}
