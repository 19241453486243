export interface CoreService {
  name: string;
  modId: string;
  brand: string;
  supportedLanguages: string[];
  type: ServiceType;
}

export enum ServiceType {
  CORE = 'core-service',
  CUSTOMER = 'customer-service'
}
