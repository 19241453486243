import { Component, OnInit } from '@angular/core';
import {
  Newsletter,
  NewsletterLocalizedResponse
} from 'src/app/shared/interfaces/newsletter.interface';
import { environment } from 'src/app/environment';
import { NewsletterService } from 'src/app/shared/services/newsletter.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/shared/services/user-service.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  createNewsletterModalOpened = false;
  newsletters: NewsletterLocalizedResponse[] = [];
  selectedNewsletter: NewsletterLocalizedResponse;
  stayOpen: boolean;
  newsletterIndex: number;
  selectedLanguage: string;
  dateFormat = environment.dateFormat;

  constructor(
    private newsletterService: NewsletterService,
    translateService: TranslateService,
    private userService: UserService
  ) {
    translateService.onLangChange.subscribe(({ lang }) => {
      this.selectedLanguage = lang;
      this.refreshNewsletters();
    });

    this.selectedLanguage = 'de';
    this.stayOpen = true;
  }

  ngOnInit(): void {
    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe(() => this.refreshNewsletters());

    // Refresh newsletters every 10 minutes
    setInterval(() => this.refreshNewsletters(), 1000 * 60 * 10);
    setInterval(() => this.onNextNewsletter(), 30000);
  }

  toggleOpen() {
    if (this.stayOpen === false) {
      this.stayOpen = true;
    } else {
      this.stayOpen = false;
    }
  }

  refreshNewsletters() {
    this.newsletterIndex = 0;
    this.newsletterService.getNewsletters(this.selectedLanguage).subscribe((newsletters) => {
      this.newsletters = newsletters;
      this.newsletters.sort((a, b) => Date.parse(a.expirationDate) - Date.parse(b.expirationDate));
      if (this.newsletters !== undefined || this.newsletters.length !== 0) {
        this.selectedNewsletter = this.newsletters[this.newsletterIndex];
      }
    });
  }

  onClicKDot(index: number) {
    this.newsletterIndex = index;
    this.selectedNewsletter = this.newsletters[this.newsletterIndex];
  }

  onNextNewsletter() {
    if (this.newsletterIndex + 1 < this.newsletters.length) {
      this.newsletterIndex++;
    } else {
      this.newsletterIndex = 0;
    }
    this.selectedNewsletter = this.newsletters[this.newsletterIndex];
  }

  onPreviousNewsletter() {
    if (this.newsletterIndex - 1 >= 0) {
      this.newsletterIndex--;
    } else {
      this.newsletterIndex = this.newsletters.length - 1;
    }
    this.selectedNewsletter = this.newsletters[this.newsletterIndex];
  }
}
